<template>
  <b-row>
    <b-col cols="4">
      <b-carousel id="carousel-example-generic " controls indicators>
        <b-carousel-slide
          v-for="(item, i) in row.item.images"
          :key="i"
          :img-src="item.url"
          style="height: 200px"
        />
      </b-carousel>
    </b-col>
    <b-col cols="3">
      <icon-media
        icon="ClockIcon"
        keyText=" توقيت البداية "
        :text="$moment(row.item.from_time, ['h:mm']).locale('ar').format('hh:mm A')"
        class="mb-1"
      ></icon-media>
      <icon-media
        icon="ClockIcon"
        keyText=" توقيت النهاية "
        :text="$moment(row.item.to_time, ['h:mm']).locale('ar').format('hh:mm A')"
        class="mb-1"
      ></icon-media>
      <icon-media
        v-if="row.item.category"
        icon="GridIcon"
        keyText="الفئة"
        :text="row.item.category.name"
        class="mb-1"
      ></icon-media>
      <icon-media
        icon="InfoIcon"
        keyText="هل هي مجانية"
        :text="!row.item.is_payment ? 'نعم' : 'لا'"
        class="mb-1"
      ></icon-media>
      <icon-media
        v-if="row.item.cost"
        icon="InfoIcon"
        keyText="الكلفة"
        :text="row.item.cost.toLocaleString('en-US')"
        class="mb-1"
      ></icon-media>
      <icon-media
        v-if="row.item.payment_method"
        icon="InfoIcon"
        keyText="طريقة الدفع"
        :text="row.item.payment_method == 'C' ? 'كاش' : 'إلكتروني'"
        class="mb-1"
      ></icon-media>
      <icon-media
        icon="InfoIcon"
        keyText="الحد الأعلى للحجز"
        :text="row.item.number_per_user"
        class="mb-1"
      ></icon-media>
    </b-col>
    <b-col cols="5">
      <h6>التفاصيل</h6>
      <p>
        {{ row.item.description }}
      </p>
      <hr />
      <div v-if="row.item.reservation_information">
        <h6>تفاصيل الحجز</h6>
        <p>
          {{ row.item.reservation_information }}
        </p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCarousel, BCarouselSlide } from "bootstrap-vue";
import IconMedia from "@/components/icon-media.vue";

export default {
  props: {
    row: {
      type: Object,
    },
  },
  components: {
    BRow,
    BCol,
    BCarousel,
    BCarouselSlide,
    IconMedia,
  },
};
</script>
