import { YesOrNo } from "@/utils/items-enam";
import { paymentMethod as optionsPaymentMethod } from "@/utils/items-enam";

export default{
    data() {
        return {
          paymentMethod:optionsPaymentMethod,
            fields: [
                { key: "title", label: "اسم", sortable: true },
                { key: "starting_date", label: "تاريخ البداية" },
                { key: "closing_date", label: "تاريخ النهاية" },
                { key: "max_member", label: "عدد المقاعد الكلي" },
                { key: "actions", label: "" },
              ],

              estatesFormSchema: {
                title: {
                  component: "FormInput",
                  key: "title",
                  attrs: {
                    label: "اسم",
                    rules: "required",
                  },
                },
                is_reservation: {
                  component: "FormSelect",
                  key: "is_reservation",
                  attrs: {
                    id: "is_reservation",
                    label: "هل يوجد حجز",
                    rules: "required",
                    reduce: (el) => el.value,
                    options: YesOrNo,
                  },
                },
                reservation_information: {
                  key: "reservation_information",
                },
        
                // address: {
                //   component: "FormInput",
                //   key: "address",
                //   attrs: {
                //     label: "العنوان",
                //     rules: "required",
                //   },
                // },
                starting_date: {
                  component: "FormDatepicker",
                  key: "starting_date",
                  attrs: {
                    id: "starting_date_activity",
                    label: "تاريخ البداية",
                    rules: "required",
                    placeholder: "YY-MM-DD"
                  },
                },
                closing_date: {
                  component: "FormDatepicker",
                  key: "closing_date",
                  attrs: {
                    id: "closing_date_activity",
                    label: "تاريخ النهاية",
                    rules: "required",
                    placeholder: "YY-MM-DD",
                  },
                },
                from_time: {
                  component: "FormTimepicker",
                  key: "from_time",
                  attrs: {
                    id: "from_time_activity",
                    label: "توقيت البدء",
                    options: "time",
                    rules: "required",
                    placeholder: "hh-mm",
                  },
                },
                to_time: {
                  component: "FormTimepicker",
                  key: "to_time",
                  attrs: {
                    id: "to_time_activity",
                    label: "توقيت النهاية",
                    options: "time",
                    rules: "required",
                    placeholder: "hh-mm",
                  },
                },
                description: {
                  component: "FormTextarea",
                  key: "description",
                  attrs: {
                    label: "التفاصيل",
                    rules: "required",
                  },
                },
                is_payment: {
                  component: "FormSelect",
                  key: "is_payment",
                  attrs: {
                    id: "is_payment",
                    label: "هل هي مجانية",
                    rules: "required",
                    reduce: (el) => el.value,
                    options: [
              { label: "نعم", value: 0 },
              { label: "لا", value: 1 },
            ],
                  },
                },
                cost: {
                  key: "cost",
                },
                payment_method: {
                  key: "payment_method",
                },
        
                max_member: {
                  component: "FormMask",
                  key: "max_member",
                  attrs: {
                    label: "عدد المقاعد الكلي",
                    options: "number",
                    rules: "required",
                    dir: "ltr",
                    placeholder: "",
                  },
                },
                number_per_user: {
                  component: "FormMask",
                  key: "number_per_user",
                  attrs: {
                    label: "الحد الأعلى للحجز",
                    options: "number",
                    rules: "required",
                    dir: "ltr",
                    placeholder: "",
                  },
                },
                images: {
                  key: "images",
                },
              },
        };
      },

}